import React from 'react';
import NetlifyForm from 'react-netlify-form'

function ProjectHomeOne() {
    return (
        <>
            <section className="cushee-project-area pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="cushee-project-box wow animated slideInUp"
                                data-wow-duration="1000ms"
                                data-wow-delay="0ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="cushee-project-content">
                                            <h3 className="title">
                                                Start earning with Cushee
                                            </h3>
                                            <p>We will email you when we launch the app.</p>
                                            <NetlifyForm name='app-lauch'>
                                    {({ loading, error, success }) => (
                                        <div>
                                        {loading &&
                                            <div>Loading...</div>
                                        }
                                        {error &&
                                            <div>Your information was not sent. Please try again later.</div>
                                        }
                                        {success &&
                                            <div>Thank you for subscribing!</div>
                                        }
                                        {!loading && !success &&
                                            <div>
                                                <div className="input-box mt-30">
                                                    <input type="text" placeholder="Your email" name="email"/>
                                                    <button type="submit">Signup</button>
                                                </div>
                                            </div>
                                        }
                                        </div>
                                    )}
                                </NetlifyForm>

                                        </div>
                                    </div>
                                </div>
                                <div className="cushee-project-thumb">
                                    <img src="assets/images/project-thumb.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProjectHomeOne;
