import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../shared/FooterHomeOne';
import Drawer from '../Mobile/Drawer';
import Header from '../shared/Header';
import HeroService from '../shared/HeroService';
import thumb from '../../assets/images/service-details-thumb.jpg';

function DetailsAboutUs() {
    return (
        <>
            <section className="cushee-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                <div className="content">
                                    <p>
                                    Cushee is a social commerce startup with a mission to promote Canadian products to North America market.
                                    </p>
                                    <span></span>
                                    <p>
                                    Our founders are originally from Vietnam. We adapt a strategy to source both Canadian and Vietnamese products at the beginning, and later on we will increase the percentage of Canadian products on our social commerce platform.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function AboutUs() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroService title="About CUSHEE" />
            <DetailsAboutUs />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default AboutUs;
