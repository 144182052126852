import React from 'react';

function ServicesHomeTwo({ className }) {
    return (
        <>
            <section className={`cushee-services-2-area pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="cushee-section-title">
                                <h3 className="cushee-title">How does it work</h3>
                                <p>
                                    The full monty spiffing good time no biggie cack grub fantastic.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4">
                            <div className="cushee-section-title text-right">
                                <a className="main-btn" href="#">
                                    View all Features <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="cushee-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-bolt" />
                                </div>
                                <h4 className="title">Fast and intuitive</h4>
                                <p>
                                    Oxford posh bevvy give us a bell gutted mate spend a penny
                                    quaint cockup plastered.
                                </p>
                                <a href="#">
                                    Read Mor <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="cushee-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-home" />
                                </div>
                                <h4 className="title">Fast and intuitive</h4>
                                <p>
                                    Oxford posh bevvy give us a bell gutted mate spend a penny
                                    quaint cockup plastered.
                                </p>
                                <a href="#">
                                    Read Mor <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="cushee-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-link" />
                                </div>
                                <h4 className="title">Fast and intuitive</h4>
                                <p>
                                    Oxford posh bevvy give us a bell gutted mate spend a penny
                                    quaint cockup plastered.
                                </p>
                                <a href="#">
                                    Read Mor <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="cushee-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-bell" />
                                </div>
                                <h4 className="title">Fast and intuitive</h4>
                                <p>
                                    Oxford posh bevvy give us a bell gutted mate spend a penny
                                    quaint cockup plastered.
                                </p>
                                <a href="#">
                                    Read Mor <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="cushee-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-cog" />
                                </div>
                                <h4 className="title">Fast and intuitive</h4>
                                <p>
                                    Oxford posh bevvy give us a bell gutted mate spend a penny
                                    quaint cockup plastered.
                                </p>
                                <a href="#">
                                    Read Mor <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="cushee-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-lock" />
                                </div>
                                <h4 className="title">Fast and intuitive</h4>
                                <p>
                                    Oxford posh bevvy give us a bell gutted mate spend a penny
                                    quaint cockup plastered.
                                </p>
                                <a href="#">
                                    Read Mor <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeTwo;
