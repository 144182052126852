import React from 'react';
import Partner1 from '../../assets/images/partner/collagen-canada.avif';
import PartnerYomo from '../../assets/images/partner/yomo.jpg';
import PartnerNuskin from '../../assets/images/partner/NuSkin.png';
import Sponser1 from '../../assets/images/sponser-1.png';
import Sponser10 from '../../assets/images/sponser-10.png';
import Sponser2 from '../../assets/images/sponser-2.png';
import Sponser3 from '../../assets/images/sponser-3.png';
import Sponser4 from '../../assets/images/sponser-4.png';
import Sponser5 from '../../assets/images/sponser-5.png';
import Sponser6 from '../../assets/images/sponser-6.png';
import Sponser7 from '../../assets/images/sponser-7.png';
import Sponser8 from '../../assets/images/sponser-8.png';
import Sponser9 from '../../assets/images/sponser-9.png';
import sponserShape from '../../assets/images/sponser-shape.png';

function FeaturedPartner({ className }) {
    return (
        <>
            <section className={`cushee-sponser-area pb-100 ${className}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cushee-section-title text-center">
                                <h3 className="cushee-title">
                                Featured Brands On Cushee
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cushee-sponser-box d-flex justify-content-center">
                                <div className="sponser-item">
                                    <img src={Partner1} alt="" />
                                </div>                                     
                                <div className="sponser-item">
                                    <img src={PartnerYomo} alt="" />
                                </div>                                        
                                <div className="sponser-item">
                                    <img src={PartnerNuskin} alt="" />
                                </div>                                
                                {/* 
                                <div className="sponser-item">
                                    <img src={Sponser2} alt="" />
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser3} alt="" />
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser4} alt="" />
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser5} alt="" />
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser6} alt="" />
                                </div>
                                */} 
                            </div>
                               {/* 
                            <div className="cushee-sponser-box item-2 d-flex justify-content-center">
                                <div className="sponser-item">
                                    <img src={Sponser7} alt="" />
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser8} alt="" />
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser9} alt="" />
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser10} alt="" />
                                </div>
                            </div>
                                     */} 
                        </div>
                    </div>
                </div>
                <div className="sponser-shape">
                    <img src={sponserShape} alt="" />
                </div>

            </section>
        </>
    );
}

export default FeaturedPartner;
