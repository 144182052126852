import React from 'react';
import thumb from '../../assets/images/fun-fact-thumb.png';

function HeroService({ title }) {
    return (
        <>
            <div className="cushee-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="cushee-about-top-title">
                                <h2 className="title">{title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ >
    );
}

export default HeroService;
