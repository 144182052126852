import React from 'react';
import { Link } from 'react-router-dom';

import heroThumbOne from '../../assets/images/hero-thumb-1.png';
import heroThumbTwo from '../../assets/images/hero-thumb-2.png';
import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png';

import rightBanner from '../../assets/images/home/right-banner_2.png'

function HeroHomeOne() {
    return (
        <>
            <section className="cushee-hero-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="cushee-hero-content">
                                <h1 className="cushee-title">
                                    <span className='color--red'>Cushee</span> is a Beauty, Wellness and Lifestyle social commerce platform
                                </h1>
                                <p>
                                    Subscribe, share and earn passive income from socially responsible products. Download the <span className='color--red'>CUSHEE</span> app to join now!</p>
                                <ul>
                                    <li>
                                        <Link to="/coming-soon" className="item-1"><i className="fab fa-apple" /> Download for iOS</Link>
                                    </li>
                                    <li>
                                        <Link to="/coming-soon" className="item-2"><i className="fab fa-google-play" /> Download for
                                            Android</Link>

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="cushee-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <img src={rightBanner} alt="" />
                                </div>

                          
                            </div>
                        </div>
                    </div>
                </div>
                {/*
                // floating icon
                <div className="hero-shape-1">
                    <img src={shapeTwo} alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeThree} alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeFour} alt="" />
                </div>
                */}
            </section>
        </>
    );
}

export default HeroHomeOne;
