import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <a href="/#value-add">Value-add</a>
                </li>
                <li>
                    <a href="/#business-model">
                        Business model
                    </a>
                </li>
                <li>
                    <a href="/#faq">
                        FAQ
                    </a>
                </li>
                <li>
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
