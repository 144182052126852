import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import BlogHomeOne from './BlogHomeOne';
import FaqHomeOne from './FaqHomeOne';
import FeaturesHomeOne from './FeaturesHomeOne';
import FooterHomeOne from '../shared/FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import PricingHomeOne from './PricingHomeOne';
import ProductShowCase from './ProductShowCase';
import AboutHomeTwo from './AboutHomeTwo';
import ProjectHomeOne from './ProjectHomeOne';
import ServicesHomeOne from './ServicesHomeOne';
import FeaturedPartner from './FeaturedPartner';
import CusheeOnTheNews from './CusheeOnTheNews';
import TeamHomeOne from './TeamHomeOne';
import TestimonialHomeOne from './TestimonialHomeOne';
import TrafficHomeOne from './TrafficHomeOne';

function CusheeHome() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <HeroHomeOne />
            <ProductShowCase />
            <ServicesHomeOne />
            <AboutHomeTwo />
            {/* <FeaturesHomeOne /> */}
            {/* <TrafficHomeOne /> */}
            {/* <TestimonialHomeOne /> */}
            {/* <TeamHomeOne /> */}
            {/* <PricingHomeOne /> */}
            <FaqHomeOne />
            <FeaturedPartner />
            <CusheeOnTheNews />
            {/* <BlogHomeOne /> */}
            <ProjectHomeOne />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default CusheeHome;
