import React, { useRef } from 'react';
import Slider from 'react-slick';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import product1 from '../../assets/images/home/products/1.jpeg';
import product2 from '../../assets/images/home/products/2.jpeg';
import product3 from '../../assets/images/home/products/3.jpeg';
import product4 from '../../assets/images/home/products/4.jpeg';
import product5 from '../../assets/images/home/products/5.jpeg';
import nuskin1 from '../../assets/images/home/products/nuskin/1_1.jpeg';
import nuskin2 from '../../assets/images/home/products/nuskin/2_1.webp';
import nuskin3 from '../../assets/images/home/products/nuskin/3_1.webp';
import nuskin4 from '../../assets/images/home/products/nuskin/4_1.png';
import nuskin5 from '../../assets/images/home/products/nuskin/5_1.webp';
import nuskin6 from '../../assets/images/home/products/nuskin/6_1.jpeg';
import nuskin7 from '../../assets/images/home/products/nuskin/7_1.webp';
import nuskin8 from '../../assets/images/home/products/nuskin/8_1.webp';
import nuskin9 from '../../assets/images/home/products/nuskin/9_1.jpeg';
import nuskin10 from '../../assets/images/home/products/nuskin/10_1.png';
import nuskin11 from '../../assets/images/home/products/nuskin/11_1.webp';
import nuskin12 from '../../assets/images/home/products/nuskin/12_1.webp';


import shape12 from '../../assets/images/shape/cosmetic1.png';
import shape13 from '../../assets/images/shape/cosmetic2.png';
import shape14 from '../../assets/images/shape/cosmetic3.png';
import shape15 from '../../assets/images/shape/shape-15.png';


function ProductShowCase() {
    const sliderRef = useRef();
    // const sliderNext = () => {
    //     sliderRef.current.slickNext();
    // };
    // const sliderPrev = () => {
    //     sliderRef.current.slickPrev();
    // };
    const settings = {
        autoplay: false,
        arrows: false,
        dots: true,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            <section className="cushee-showcase-area">
                <SimpleReactLightbox>
                    <SRLWrapper>
                        <div className="container">

                            <div className="row cushee-showcase-slider">
                                <div className="col-lg-12">
                                    <Slider ref={sliderRef} {...settings}>
                                        <div>
                                            <div className="cushee-showcase-item mt-30">
                                                <a className="cushee-image-popup">
                                                    <img src={nuskin1} alt=""/>
                                                </a>
                                            </div>
                                        </div>     
                                        <div>
                                            <div className="cushee-showcase-item mt-30">
                                                <a className="cushee-image-popup">
                                                    <img src={nuskin2} alt="" />
                                                </a>
                                            </div>
                                        </div>     
                                        <div>
                                            <div className="cushee-showcase-item mt-30">
                                                <a className="cushee-image-popup">
                                                    <img src={nuskin3} alt="" />
                                                </a>
                                            </div>
                                        </div>     
                                        <div>
                                            <div className="cushee-showcase-item mt-30">
                                                <a className="cushee-image-popup">
                                                    <img src={nuskin4} alt="" />
                                                </a>
                                            </div>
                                        </div>     
       
                                        <div>
                                            <div className="cushee-showcase-item mt-30">
                                                <a className="cushee-image-popup">
                                                    <img src={nuskin6} alt="" />
                                                </a>
                                            </div>
                                        </div>       
                                        <div>
                                            <div className="cushee-showcase-item mt-30">
                                                <a className="cushee-image-popup">
                                                    <img src={nuskin7} alt="" />
                                                </a>
                                            </div>
                                        </div>     
                                        <div>
                                            <div className="cushee-showcase-item mt-30">
                                                <a className="cushee-image-popup">
                                                    <img src={nuskin8} alt="" />
                                                </a>
                                            </div>
                                        </div>     
                                        <div>
                                            <div className="cushee-showcase-item mt-30">
                                                <a className="cushee-image-popup">
                                                    <img src={nuskin9} alt="" />
                                                </a>
                                            </div>
                                        </div>     
                                        <div>
                                            <div className="cushee-showcase-item mt-30">
                                                <a className="cushee-image-popup">
                                                    <img src={nuskin10} alt="" />
                                                </a>
                                            </div>
                                        </div>     
                                        <div>
                                            <div className="cushee-showcase-item mt-30">
                                                <a className="cushee-image-popup">
                                                    <img src={nuskin11} alt="" />
                                                </a>
                                            </div>
                                        </div>     
                                        <div>
                                            <div className="cushee-showcase-item mt-30">
                                                <a className="cushee-image-popup">
                                                    <img src={nuskin12} alt="" />
                                                </a>
                                            </div>
                                        </div>                                        

                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className="showcase-shape-1">
                            <img src={shape14} alt="" />
                        </div>
                        <div className="showcase-shape-2">
                            <img src={shape13} alt="" />
                        </div>
                        <div className="showcase-shape-3">
                            <img src={shape12} alt="" />
                        </div>
                        <div className="showcase-shape-4">
                            <img src={shape15} alt="" />
                        </div>
                    </SRLWrapper>
                </SimpleReactLightbox>
            </section>
        </>
    );
}

export default ProductShowCase;
