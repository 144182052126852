import React from 'react';

function ContactUs() {
    return (
        <>
            <div className="cushee-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="cushee-about-top-title">
                                <h2 className="title">Let's connect</h2>
                                <br></br>
                                <br></br>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </ >
    );
}

export default ContactUs;