import React from 'react';
import NetlifyForm from 'react-netlify-form'




function Forms() {
    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Get in touch</h3>
                                <p>Looking for help? Fill the form and start a new adventure.</p>
                                <div className="single-info">
                                    <h5>Headquaters</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        18 King Street East, Suite 1400
                                        <br /> Toronto, Ontario
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        (+416) 856 5759
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Support</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        support@cushee.ca
                                        <br />
                                        contact@cushee.ca
                                    </p>
                                </div>
                                <div className="ab-social">
                                    <h5>Follow Us</h5>
                                    <a className="fac" href="https://www.facebook.com/cusheeca">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a className="you" href="https://www.instagram.com/cusheeca/">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                    <a className="lin" href="https://www.linkedin.com/company/cushee/about/">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Let’s Connect</h4>
                                <p>Whether you are a supplier or reseller, we are here to make your business profitable, send us a message to explore our partnership.</p>

                                <NetlifyForm name='contact'>
                                    {({ loading, error, success }) => (
                                        <div>
                                        {loading &&
                                            <div>Loading...</div>
                                        }
                                        {error &&
                                            <div>Your information was not sent. Please try again later.</div>
                                        }
                                        {success &&
                                            <div>Thank you for contacting us!</div>
                                        }
                                        {!loading && !success &&
                                            <div>
                                    <div className="col-md-12">
                                        <input type="text" name="first-name" placeholder="First Name" />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" name="last-name" placeholder="Last Name" />
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email Address"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="number"
                                            name="phone"
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" name="suject" placeholder="Subject" />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="message"
                                            placeholder="How can we help?"
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="condition-check">
                                            <input id="terms-conditions" type="checkbox" />
                                            <label htmlFor="terms-conditions">
                                                I agree to the <a href="#">Terms & Conditions</a>
                                            </label>
                                        </div>
                                    </div>
                                    <div data-netlify-recaptcha="true"></div>

                                    <div className="col-md-6 text-right">
                                        <input type="submit" name="submit" value="Send Message" />
                                    </div>
                                            </div>
                                        }
                                        </div>
                                    )}
                                </NetlifyForm>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="bisylms-map">
                <iframe
                    title="map"
                    src="https://maps.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.9653099312923!2d-79.38271478450238!3d43.6488900791215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34d2aa57c079%3A0x62eeef72027a4495!2s18%20King%20St%20E%20Suite%201400%2C%20Toronto%2C%20ON%20M5C%201C4!5e0!3m2!1sen!2sca!4v1647475982233!5m2!1sen!2sca"
                ></iframe>
            </div>
        </>
    );
}

export default Forms;
