import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function FooterHomeOne({ className }) {
    return (
        <>
            <section className={`cushee-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#/">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <p>
                                    Cushee is a Beauty, Wellness and Lifestyle social commerce platform.
                                </p>
                                {/* <a href="#/">
                                    Read More <i className="fal fa-arrow-right" />
                                </a> */}
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/cusheeca">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://instagram.com/cusheeca">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/cushee/about/">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Company</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                    {/* <li>
                                        <a href="#/">Careers</a>
                                    </li> */}
                                    <li>
                                        <Link to="/service">Service</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Support</h4>
                                <ul>
                                    {/* <li>
                                        <a href="#/">Resources</a>
                                    </li>
                                    <li>
                                        <a href="#/">FAQs</a>
                                    </li>
                                    <li>
                                        <a href="#/">Warranty</a>
                                    </li> */}
                                    <li>
                                        <Link to="/terms-of-use">Terms of Use</Link>
                                    </li>
                                    <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul>
                                    <li>
                                        <a href="#/">
                                            <i className="fal fa-envelope" /> info@cushee.ca
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#/">
                                            <i className="fal fa-map-marker-alt" /> 1400-18 King Street East, Toronto, Ontario
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <Link to="/coming-soon" className="item-1"><i className="fab fa-apple" /> Download for iOS</Link>
                                        </li>
                                        <li>
                                            <Link to="/coming-soon" className="item-2"><i className="fab fa-google-play" /> Download for
                                                Android</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2022 Cushee.ca. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
