import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../shared/FooterHomeOne';
import Drawer from '../Mobile/Drawer';
import Header from '../shared/Header';
import HeroService from '../shared/HeroService';

function DetailsService() {
    return (
        <>
            <section className="cushee-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                <div className="content">
                                    <h3 className="title">For suppliers</h3>
                                    <p>
                                    Our company offers supports to upload your products' information and images onto our platform for FREE.
                                    </p><p>
                                    Through our online resellers network, our products' messages will be broadcasted across social media channel.
                                    </p><p>
                                    Our platform will send customers' requests to you for delivery process.
                                    </p>
                                    <span></span>
                                    <h3 className="title">For exporters</h3>
                                    <p>
                                    We will provide guidance on how to export to Canada through B2B or B2C channel.
                                    <br></br>We aim to support exporters from Vietnam to sell high quality products to Canada.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function Service() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroService title="Services" />
            <DetailsService />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Service;
