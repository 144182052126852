import React, { useState } from 'react';

function FaqHomeOne({ className }) {
    const [showQues, setQues] = useState(1);
    const openQuestion = (value) => {
        setQues(value);
    };
    return (
        <>
            <section id="faq" className={`cushee-faq-area pb-95 ${className || ''} anchor`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cushee-section-title text-center">
                                <h3 className="cushee-title">Frequently asked questions</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="faq-accordion wow fadeInRight mt-30"
                                data-wow-duration="1500ms"
                            >
                                <div
                                    className="accrodion-grp animated fadeIn faq-accrodion wow"
                                    data-wow-duration="1500ms"
                                    data-grp-name="faq-accrodion"
                                >
                                    <div
                                        onClick={() => openQuestion(1)}
                                        className={`accrodion ${showQues === 1 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>How do I earn passive-income with CUSHEE?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 1 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                    When you create a CUSHEE account, you will also create a store for your own. You will then be presented with a collection of quality products provided by selected suppliers. Choose products your network likes, list those product to your store and share your store to your social channels, e.g: Facebook, Instagram, Twitter, etc.. People can start purchase the products and you can earn commissions from the sales.
                                              </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(2)}
                                        className={`accrodion ${showQues === 2 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>How will CUSHEE help me with reselling?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 2 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                <p>
                                                CUSHEE is a Canadian reselling platform where resellers earn passive-income by promoting brands of CUSSHEE's partners:
                                                <li>We partner with suppliers and manufacturers that provide us good quality products on our app. Reseller gets all these products at one place. Hence, they do not have to approach different suppliers for their different requirements.</li>
                                                <li>Reseller is updated with the latest market trends. The latest products are updated daily on the 'For You' section of the Cushee app. Reseller gets notifications about theses products.</li>
                                                <li>Cushee and Cushee's partners takes responsibility for shipping and delivery. Resellers does not have to worry about logistics or delivery.</li>
                                                <li>Easy and hassle-free returns and exchanges are offered. Unless a sale is final, customers can replace or return a product if there is a problem.</li>
                                                <li>Short videos in Cushee Business Academy are the best way for resellers to know more about reselling. Whether they have prior experience or are new to reselling, these short introduction videos will help everyone learn and help them grow as an entrepreneur.</li>
                                                <li>The 'Cushee Community' discord is a place where you connect and grow with other resellers. You can take part in this and follow the tips shared by experienced resellers.</li>
                                                </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div
                                className="faq-accordion wow fadeInRight mt-30"
                                data-wow-duration="1500ms"
                            >
                                <div
                                    className="accrodion-grp animated fadeIn faq-accrodion wow"
                                    data-wow-duration="1500ms"
                                    data-grp-name="faq-accrodion"
                                >
                                    <div
                                        onClick={() => openQuestion(3)}
                                        className={`accrodion ${showQues === 3 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>How much margin can we add?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 3 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                Resellers typically charge a margin between 5% - 20%.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(4)}
                                        className={`accrodion ${showQues === 4 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>How is the price of a shipment determined?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 4 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                    Shipment cost is calculated based on the volume and the total weight of ​​shipment. To know more about the exact shipping cost: 
                                                    <li>Select the products for which you want to check shipping cost</li>
                                                    <li>Select "Check Shipping" in the supplier option </li>
                                                    <li>Enter your customer's postcode and select "CHECK"</li>
                                                    <li>You will get shipping cost Get the right price If you are not sure about the address of your customers, refer to the "Rate Table". Here you can find all Canada shipping charges.</li>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="faq-text text-center pt-40">
                                <p>
                                    Can't find an answer?{' '}
                                    <a href="mailto:contact@cushee.ca">Email us</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FaqHomeOne;
