import React from 'react';
import IconOne from '../../assets/images/icon/passive.png';
import IconTwo from '../../assets/images/icon/authentic.png';
import IconThree from '../../assets/images/icon/shipping.png';
import IconFour from '../../assets/images/icon/sustainable.png';

function ServicesHomeOne({ className }) {
    return (
        <section id="value-add" className={`cushee-service-area pt-90 pb-100 ${className} anchor`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="cushee-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={IconOne} alt="" />
                                <span>1</span>
                            </div>
                            <h4 className="cushee-title">Passive income</h4>
                            <p>Earn passive income through your social channels</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="cushee-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={IconTwo} alt="" />
                                <span>2</span>
                            </div>
                            <h4 className="cushee-title">Authentic brands</h4>
                            <p>Fast and free shipping on orders over $75</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="cushee-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <img src={IconThree} alt="" />
                                <span>3</span>
                            </div>
                            <h4 className="cushee-title">No stockings</h4>
                            <p>Delivery is handled by Cushee and partners</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="cushee-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <img src={IconFour} alt="" />
                                <span>4</span>
                            </div>
                            <h4 className="cushee-title">Socially responsible </h4>
                            <p>Products are sourced locally or responsibly</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
