import React from 'react';
import aboutThumb from '../../assets/images/undraw.png';

function AboutHomeTwo() {
    return (
        <>
            <section id="business-model" className="cushee-about-area mb-100 anchor">
                <div className="hero-shape-1">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="cushee-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-thumb">
                                            <img src={aboutThumb} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="cushee-about-content">
                                            <span>Marketing</span>
                                            <h3 className="title">
                                            Our business model
                                            </h3>
                                            <p>
                                            CUSHEE brings trusted brands to customers through online marketing and social media channels, with no upfront marketing costs.
</p><br></br><p>
Resellers of CUSHEE can access a broad list of authentic and high quality products. Resellers can opt to sell products to acquaintances, friends and social media followers and receive a passive income up to 20% margin. Resellers do not have to handle stocking, packing, delivering or collecting payments but rather these tasks would be sorted out by CUSHEE and its suppliers. 
                                            </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="cushee-about-service mt-30">
                                                    <div className="icon">
                                                        <i className="fal fa-check" />
                                                    </div>
                                                    <h4 className="title">Canadian-focused</h4>
                                                    <p>
                                                    Absolute distinction with oversea products to promote and preserve true Canadian products
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="cushee-about-service mt-30">
                                                    <div className="icon">
                                                        <i className="fal fa-check" />
                                                    </div>
                                                    <h4 className="title">Free online marketing</h4>
                                                    <p>
                                                       Suppliers pay commission when a purchase is final
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutHomeTwo;
